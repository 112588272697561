import { DashboardConfiguration } from "./sharedTypes";
import ProgressBar from "./progressBar";
import { sendChunk, acknowledge } from "./storage";
import { TranslationProvider } from "./translationProvider";
import { RecordingActions, RecordingControls } from "./recordingControls";
import { invokeCallback } from "./listenerService";

const DEFAULT_MIME_TYPE = "video/webm";
const DEFAULT_TIME_SLICE = 3 * 1000; // 3 seconds

export default class Dashboard {
  // HTML
  private progressBar: ProgressBar;
  private recordingControls: RecordingControls;
  private videoElementContainer: HTMLDivElement;
  private videoElement: HTMLVideoElement;
  private delayTimeSpan: HTMLSpanElement;
  private displayTextDiv: HTMLDivElement;
  //private displayTextTitleDiv: HTMLDivElement;
  private elements: HTMLElement[];
  private appHeader: HTMLElement;
  private appTitle: HTMLHeadingElement;
  private stageTitle: HTMLHeadingElement;
  private fullScreenWrapper: HTMLDivElement;
  private stageInstructions: HTMLElement;

  // MEDIA
  private cameraStream: MediaStream;
  private mediaRecorder: MediaRecorder;
  private buffer: BlobPart[];
  private currentTime: number = 0;
  private delayTime: number = 0;
  private isRecording: boolean;

  private get totalParts() {
    return Math.ceil(
      this.dashboardConfiguration.recordingTime / DEFAULT_TIME_SLICE
    );
  }

  constructor(
    private window: Window,
    private dashboardConfiguration: DashboardConfiguration,
    private appElement: HTMLElement,
    private translationProvider: TranslationProvider
  ) {
    this.progressBar = new ProgressBar(appElement);

    this.recordingControls = new RecordingControls(
      appElement,
      this.translationProvider
    );

    this.videoElementContainer = appElement.ownerDocument.createElement("div");
    this.videoElementContainer.className = "video-container";
    this.videoElement = appElement.ownerDocument.createElement("video");
    this.videoElement.className = "video-preview";
    this.videoElement.autoplay = true;
    this.videoElement.muted = true;
    this.videoElementContainer.appendChild(this.videoElement);

    this.delayTimeSpan = appElement.ownerDocument.createElement("span");
    this.delayTimeSpan.className = "current-delay-time";
    this.videoElementContainer.appendChild(this.delayTimeSpan);

    this.appTitle = appElement.ownerDocument.createElement("h1");
    this.appTitle.className = "app-title";
    this.appTitle.innerHTML = "VÍDEO ENTREVISTA"; //dashboardConfiguration.title;

    this.stageTitle = appElement.ownerDocument.createElement("h2");
    this.stageTitle.className = "stage-title"; // título da etapa
    this.stageTitle.innerHTML = dashboardConfiguration.title
      ? dashboardConfiguration.title.toUpperCase()
      : "";

    this.appHeader = appElement.ownerDocument.createElement("header");
    this.appHeader.className = "app-header";
    this.appHeader.appendChild(this.appTitle);
    this.appHeader.appendChild(this.stageTitle);

    this.displayTextDiv = appElement.ownerDocument.createElement("div");
    this.displayTextDiv.className = "instructions";
    this.displayTextDiv.innerHTML = dashboardConfiguration.instructions;

    this.stageInstructions = appElement.ownerDocument.createElement("details");
    this.stageInstructions.setAttribute("open", "");
    this.stageInstructions.className = "instructions-details";

    const showInstructionsIcon = appElement.ownerDocument.createElement("span");
    showInstructionsIcon.className = "material-icons show";
    showInstructionsIcon.innerHTML = "help";

    const hideInstructionsIcon = appElement.ownerDocument.createElement("span");
    hideInstructionsIcon.className = "material-icons hide";
    hideInstructionsIcon.innerHTML = "cancel";

    const detailsSummary = appElement.ownerDocument.createElement("summary");
    detailsSummary.appendChild(hideInstructionsIcon);
    detailsSummary.appendChild(showInstructionsIcon);

    this.stageInstructions.appendChild(detailsSummary);
    this.stageInstructions.appendChild(this.displayTextDiv);

    this.fullScreenWrapper = appElement.ownerDocument.createElement("div");
    this.fullScreenWrapper.className = "fullscreen-wrapper";

    this.fullScreenWrapper.append(
      this.progressBar.element(),
      this.stageInstructions,
      this.videoElementContainer,
      this.recordingControls.element()
    );

    this.elements = [this.appHeader, this.fullScreenWrapper];
  }

  init() {
    this.attach();
    this.bindEvents();
    this.requestCamera();
  }

  private bindEvents() {
    this.recordingControls.addClickListener(
      RecordingActions.START_RECORDING,
      () => {
        // Reinicie o buffer toda vez que um usuário iniciar a gravação de um vídeo
        this.recordingControls.disableButtons();
        this.buffer = [];
        this.videoElement.removeAttribute("src");
        this.videoElement.muted = true;
        this.videoElement.removeAttribute("controls");
        this.videoElement.classList.remove("player");
  
        this.requestCamera().then(() => {
          this.showDelay(5, () => {
            this.setupMediaRecorder();
            this.updateUi();
          });
        });
      }
    );
  
    this.recordingControls.addClickListener(
      RecordingActions.STOP_RECORDING,
      async () => {
        if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
          this.mediaRecorder.stop();
        }
      }
    );
  
    this.recordingControls.addClickListener(RecordingActions.SEND, async () => {
      this.recordingControls.disableButtons();
      await this.send();
      this.close();
    });
  
    this.recordingControls.addClickListener(
      RecordingActions.RETURN,
      async () => {
        // Pare a gravação antes de fechar, se necessário
        if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
          this.mediaRecorder.stop();
        }
        this.close();
      }
    );
  }

  private updateUi() {
    this.recordingControls.isRecording = this.isRecording;
  }

  private afterStop() {
    this.isRecording = false;
    this.updateUi();
    this.displayPreview();
    setTimeout(() => {
      this.progressBar.setProgress(0);
      this.progressBar.hide();
    }, 1000);
  }

  private async send() {
    await acknowledge(this.window, {
      ack: true,
      uniqueId: this.dashboardConfiguration.uniqueId,
      namespace: this.dashboardConfiguration.namespace || "default",
    });
    await invokeCallback(this.window, this.dashboardConfiguration);
  }

  private close() {
    setTimeout(() => {
      this.window.history.go(-1);
    }, 600);
  }

  private dispatchCurrent() {
    this.dispatch(this.buffer.length);
  }

  private dispatch(currentPart: number) {
    sendChunk(this.window, {
      uniqueId: this.dashboardConfiguration.uniqueId,
      data: new Blob([this.buffer[this.buffer.length - 1]], {
        type: DEFAULT_MIME_TYPE,
      }),
      part: currentPart,
      of: this.totalParts,
      namespace: this.dashboardConfiguration.namespace || "default",
    });
  }

  private showDelay(targetDelayTime: number, after: () => void) {
    const symbol = setInterval(() => {
      this.delayTimeSpan.hidden = false;
      this.delayTimeSpan.innerText = `${Math.abs(
        targetDelayTime - this.delayTime
      )}`;
      if (this.delayTime >= targetDelayTime) {
        this.delayTimeSpan.hidden = true;
        this.delayTime = 0;
        after();
        clearInterval(symbol);
        return;
      }
      this.delayTime += 1;
    }, 1000);
  }

  private setupMediaRecorder() {
    if (!this.cameraStream) {
      console.error("Nenhuma câmera disponível.");
      return;
    }

    this.mediaRecorder = new MediaRecorder(this.cameraStream, {
      mimeType: DEFAULT_MIME_TYPE,
    });

    this.mediaRecorder.addEventListener("dataavailable", (e) => {
      this.buffer.push(e.data);
      this.dispatchCurrent();
    });

    this.mediaRecorder.addEventListener("stop", (e) => {
      this.afterStop();
    });

    this.mediaRecorder.start(DEFAULT_TIME_SLICE);
    this.isRecording = true;
    this.showProgress();
  }

  private showProgress() {
    if (!this.isRecording) {
      this.currentTime = 0;
      this.progressBar.setProgress(0);
      return;
    }

    const currentProgress =
      (this.currentTime / this.dashboardConfiguration.recordingTime) * 100;
    this.progressBar.setProgress(currentProgress);
    this.progressBar.show();

    if (this.currentTime >= this.dashboardConfiguration.recordingTime) {
      this.currentTime = 0;
      this.mediaRecorder.stop();
      return;
    }

    this.currentTime += 1000;

    setTimeout(() => {
      this.showProgress();
    }, 1000);
  }

  private displayPreview() {
    const url = URL.createObjectURL(
      new Blob(this.buffer, { type: DEFAULT_MIME_TYPE })
    );
    this.videoElement.srcObject = null;
    this.videoElement.src = url;
    this.videoElement.autoplay = false;
    this.videoElement.muted = false;
    this.videoElement.controls = true;
    this.videoElement.classList.add("player");

    this.videoElement.addEventListener("playing", () => {
      this.videoElement.muted = false;
    });

    this.videoElement.addEventListener("ended", () => {
      this.videoElement.autoplay = true;
      this.videoElement.muted = true;
    });
  }

  private async requestCamera() {
    try {

      if (!this.window.navigator.mediaDevices || !this.window.navigator.mediaDevices.getUserMedia) {
        alert("getUserMedia() não é suportado no seu navegador.");
        this.appElement.innerHTML = "<h2>Não foi possível acessar a câmera.</h2>";
      }

      // Verifique se já existe uma câmera ativa e pare-a
      if (this.cameraStream) {
        this.cameraStream.getTracks().forEach((track) => track.stop());
      }

      this.cameraStream = await this.window.navigator.mediaDevices.getUserMedia(
        {
          video: {
            width: { min: 640, ideal: 1280, max: 1920 },
            height: { min: 360, ideal: 720, max: 1080 },
            facingMode: "user", // Use 'environment' para a câmera traseira
          },
          audio: true,
        }
      );

      this.videoElement.srcObject = this.cameraStream;
    } catch (err) {
      alert(err)
      this.appElement.innerHTML = "<h2>Não foi possível acessar a câmera.</h2>";
    }
  }

  private attach() {
    this.elements.forEach((element) => this.appElement.appendChild(element));
  }
}
